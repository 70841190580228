import React, { Component } from "react";
import Link from "next/link";
import Image from "next/image";
import Slider from "react-slick";
import styles from "./banner.module.css";

export default class Banner extends Component {
  constructor(props) {
    super(props);
    this.data = props.data;
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
  }

  next() {
    this.slider.slickNext();
  }

  previous() {
    this.slider.slickPrev();
  }

  render() {
    function SamplePrevArrow({ onClick }) {
      return (
        <div className={styles.prevArrow}>
          <img
            src="/icons/left-arrow.svg"
            onClick={onClick}
            width={32}
            height={18}
          />
        </div>
      );
    }

    function SampleNextArrow({ onClick }) {
      return (
        <div className={styles.nextArrow}>
          <img
            src="/icons/right-arrow.svg"
            onClick={onClick}
            width={32}
            height={18}
          />
        </div>
      );
    }

    const settings = {
      arrows: true,
      dots: true,
      infinite: true,
      speed: 600,
      slidesToShow: 1,
      slidesToScroll: 1,
      prevArrow: <SamplePrevArrow onclick={this.previous} />,
      nextArrow: <SampleNextArrow onclick={this.next} />,

      customPaging: function(i) {
        return (
          <a>
            <div className={styles.dots} />
          </a>
        );
      },
      dotsClass: styles.ulPos,
    };

    return (
      <>
        <Slider ref={(c) => (this.slider = c)} {...settings} fade>
          {this.data.map((item) => {
            return (
              <div key={item.id} className={styles.bg}>
                <div className={styles.imgDesktop}>
                  {item?.imagem?.url && (
                    <Image
                      loading="lazy"
                      src={item.imagem.url}
                      alt={item.titulo}
                      layout="fill"
                      objectFit="cover"
                      objectPosition="center"
                    />
                  )}
                </div>

                <div className={styles.imgMob}>
                  {item?.imgMob?.url && (
                    <Image
                      loading="lazy"
                      src={item.imgMob.url ? item.imgMob.url : item.imagem.url}
                      alt={item.titulo}
                      layout="fill"
                      objectFit="cover"
                      objectPosition="center"
                    />
                  )}
                </div>

                <div className={styles.bannerContent}>
                  <div className={styles.parallelogram} />
                  <div className={styles.bannerText}>
                    <h1>{item.titulo}</h1>
                    <span>{item.chamada}</span>
                    <div>
                      <Link href={item.link}>
                        <a>{item.textoBotao}</a>
                      </Link>
                      <img
                        src="/icons/arrow-right-link.svg"
                        width={24}
                        height={14}
                        loading="lazy"
                      />
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </Slider>
      </>
    );
  }
}
