import { useState, useEffect } from "react";
import Head from "components/Head";
import React from "react";
import Banner from "./Banner";
import SectionProperties from "./SectionProperties";
import SectionHighlights from "components/Highlights";
import ReceiveNews from "components/ReceiveNews";
import SectionBlog from "./SectionBlog";

export default function Home({ data }) {
  const [windowWidth, setWindowWidth] = useState(null);

  useEffect(() => {
    setWindowWidth(window.outerWidth);
  }, [windowWidth]);

  return (
    <>
      <Head data={data.seo} />
      <Banner data={data.banner} />
      <SectionProperties data={data.secaoImoveis} width={windowWidth} />
      <SectionHighlights data={data.secaoDestaques} />
      {/* <SectionBlog data={data.blogFeed} /> */}
      <ReceiveNews data={data.secaoRecebaNovidades} />
    </>
  );
}
