import Link from "next/link";
import ProprietyCard from "components/ProprietyCard";
import { Component, useState } from "react";
import Slider from "react-slick";
import Image from "next/image";
import styles from "./sectionProperties.module.css";

export default class SectionProperties extends Component {
  constructor(props) {
    super(props);
    this.data = props.data;
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.state = {
      slideBackgroundIndex: this.data.imoveis.length - 1, // variável de manipulação do carousel
      width: null,
    };
  }

  componentDidMount() {
    this.setState({
      width: window.clientWidth,
    });
  }

  next() {
    this.slider.slickNext();
  }

  previous() {
    this.slider.slickPrev();
  }

  render() {
    this.state.width;
    function SamplePrevArrow({ onClick }) {
      return (
        <img
          loading="lazy"
          className={styles.prevArrow}
          src="/icons/left-arrow-blue.svg"
          onClick={onClick}
          width={32}
          height={78}
        />
      );
    }

    function SampleNextArrow({ onClick }) {
      return (
        <img
          loading="lazy"
          className={styles.nextArrow}
          src="/icons/right-arrow-blue.svg"
          onClick={onClick}
          width={32}
          height={78}
        />
      );
    }

    const GoTo = (current, next) => {
      if (next === current) {
        return this.slider.slickGoTo(current);
      } else if (Math.abs(next - current) === 2) {
        return this.slider.slickGoTo(current + 1);
      }

      return this.slider.slickGoTo(current);
    };

    const settings = {
      draggable: false,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      variableWidth: true,
      initialSlide: 0,
      prevArrow: <SamplePrevArrow onclick={this.previous} />,
      nextArrow: <SampleNextArrow onclick={this.next} />,
      responsive: [
        {
          breakpoint: 1400,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            variableWidth: true,
          },
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            variableWidth: true,
            // centerMode: true,
            // centerPadding: '90px'
          },
        },
        {
          breakpoint: 360,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    const settings3 = {
      draggable: true,
      speed: 500,
      infinite: true,
      slidesToShow: 2,
      slidesToScroll: 1,
      centerMode: true,
      className: "carMobItem",
      centerPadding: "50px",
      initialSlide: 0,
      prevArrow: <SamplePrevArrow onclick={this.previous} />,
      nextArrow: <SampleNextArrow onclick={this.next} />,
      responsive: [
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerPadding: 0,
          },
        },
      ],
    };

    return (
      <section className={`${styles.sectionProperties} container-full`}>
        <div className="container-geral position-relative flex-column">
          <div className={`grid-cont align-self-center`}>
            <div className={styles.callText}>
              <div>
                <div className={styles.parallelogramDetail} />
                <h2>{this.data.titulo}</h2>
              </div>
              <div className={styles.button}>
                <Link href={this.data.link}>
                  <a>{this.data.textoBotao}</a>
                </Link>
                <img
                  src="/icons/arrow-right-red.svg"
                  width={24}
                  height={14}
                  loading="lazy"
                />
              </div>
            </div>
          </div>
          <div className={` ${styles.carCont}`}>
            <div className={` ${styles.carMob}`}>
              <Slider {...settings3}>
                {this.data.imoveis.map((propriety) => {
                  return (
                    <div key={propriety.id} className={styles.carouselLink}>
                      <ProprietyCard data={propriety} />
                    </div>
                  );
                })}
              </Slider>
            </div>
          </div>

          <div
            className="d-flex justify-content-lg-end position-relative"
            style={{ width: "100%" }}
          >
            <div className={`${styles.carDesktop} ${styles.car1}`}>
              <Slider
                ref={(slider) => (this.slider = slider)}
                {...{
                  draggable: false,
                  arrows: false,
                  dots: false,
                  initialSlide: this.state.slideBackgroundIndex,
                }}
                fade
              >
                {this.data.imoveis.map((propriety) => {
                  return (
                    <div key={propriety.id}>
                      <Link href={`/imovel/${propriety.slug}`}>
                        <div className={styles.backgroundCarousel}>
                          {propriety?.backgroundImagemCarousel?.url && (
                            <Image
                              loading="lazy"
                              src={propriety.backgroundImagemCarousel.url}
                              alt={propriety.nome}
                              width={propriety.backgroundImagemCarousel.width}
                              height={propriety.backgroundImagemCarousel.height}
                              layout="fixed"
                            />
                          )}
                        </div>
                      </Link>
                      <div className={styles.backgroundCarouselContent}>
                        <div className={styles.backgroundCarouselImg}>
                          <div>
                            {propriety?.iconeBackgroundCarousel?.url && (
                              <Image
                                loading="lazy"
                                src={propriety.iconeBackgroundCarousel.url}
                                width={74}
                                height={74}
                                objectFit="fill"
                              />
                            )}
                          </div>
                        </div>
                        <div className={styles.proprietyText}>
                          <div className={styles.phaseCity}>
                            <div
                              className={styles.parallelogramSpan}
                              style={{ background: propriety.etapasImovel.cor }}
                            >
                              <span>{propriety.etapasImovel.etapa}</span>
                            </div>
                            <span>{propriety.cidades.cidade}</span>
                            <span>-</span>
                            <span>{propriety.cidades.uf}</span>
                          </div>
                          <span style={{ fontSize: 25, fontWeight: 500 }}>
                            {propriety.nome}
                          </span>
                          <div className={styles.typologyDesc}>
                            <span>{propriety.tipologia}</span>
                            <span>{propriety.descricao}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </Slider>
            </div>
            <div className={`${styles.carouselContent} ${styles.carDesktop}`}>
              <Slider
                swipeToSlide={true}
                focusOnSelect={false}
                {...settings}
                beforeChange={(current, next) => {
                  GoTo(current, next);
                }}
              >
                {this.data.imoveis.map((propriety) => {
                  return (
                    <div key={propriety.id} className={styles.carouselLink}>
                      <ProprietyCard data={propriety} />
                    </div>
                  );
                })}
              </Slider>
              <div className={styles.parallelogram} />
            </div>
          </div>
        </div>
      </section>
    );
  }
}
