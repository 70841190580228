import React from "react";
import { get } from "services/actions";
import strapiImg from "utils/strapiImg";
import AppLayout from "components/AppLayout";
import HomePage from "components/pages/Home";

export default function Home({ homePage, appLayout }) {
  return (
    <>
      <AppLayout data={appLayout}>
        <HomePage data={homePage} />
      </AppLayout>
    </>
  );
}

export async function getStaticProps() {
  const header = await get("cabecalho");
  const geral = await get("configuracao-geral");
  const footer = await get("rodape");
  const data = await get("home");
  const receiveNews = await get("receba-novidades");
  const featuredProperties = await get("imoveis", {
    destaque_home: true,
  });

  // DADOS COMPONENTE: AppLayout
  const appLayout = {
    header: {
      logo: header?.logo ? strapiImg(header.logo, "", true) : "",
      menu: header?.menu ? header.menu : "",
    },
    footer: {
      logo: footer?.logo ? strapiImg(footer.logo, "", true) : "",
      redes: footer?.redes_sociais
        ? footer.redes_sociais.map(({ id, nome, link, icone }) => {
            icone = strapiImg(icone, "", true);
            return {
              id,
              nome,
              link,
              icone,
            };
          })
        : "",
      unidades: footer?.unidades ? footer.unidades : "",

      submenus: footer?.submenu ? footer.submenu : "",
      whatsappIcon: strapiImg(footer?.icone_whatsapp_rodape, "", true),
      openChatBot: footer?.habilitar_chatbot,
    },
  };

  // DADOS COMPONENTE: HomePage
  const homePage = {
    seo: {
      titulo: data?.SEO ? data.SEO.title : "",
      descricao: data?.SEO ? data.SEO.description : "",
      imagem: data?.SEO ? strapiImg(data.SEO.image, "", true) : "",
      favicon: strapiImg(geral.favicon, "", true),
    },
    banner: data?.banner
      ? data?.banner.map((item) => {
          return {
            id: item?.id,
            titulo: item?.titulo,
            chamada: item?.chamada,
            link: item?.link,
            textoBotao: item?.texto_botao,
            identificador: item?.identificador,
            imagem: strapiImg(item?.imagem, "", true),
            imgMob: strapiImg(item?.imagem_mobile, "", true),
          };
        })
      : "",
    secaoImoveis: {
      titulo: data?.secao_imoveis ? data?.secao_imoveis?.titulo : "",
      textoBotao: data?.secao_imoveis ? data?.secao_imoveis?.texto_botao : "",
      link: data?.secao_imoveis ? data.secao_imoveis.link : "",
      imoveis: featuredProperties.map((item) => {
        const imagemCard = strapiImg(item.imovel_imagem, "", true);
        const backgroundImagemCarousel = strapiImg(
          item.imovel_background,
          "",
          true
        );
        const iconeBackgroundCarousel = strapiImg(item.icone_imovel, "", true);

        return {
          id: item?.id,
          nome: item?.nome,
          cidades: item?.cidades,
          etapasImovel: item?.etapas_imovel,
          tipologia: item?.tipologia,
          descricao: item?.descricao,
          slug: item?.slug,
          imagemCard,
          backgroundImagemCarousel,
          iconeBackgroundCarousel,
        };
      }),
    },
    secaoDestaques: {
      titulo: data?.secao_destaque ? data?.secao_destaque?.titulo : "",
      chamada: data?.secao_destaque ? data?.secao_destaque?.chamada : "",
      icones: data?.secao_destaque ? data?.secao_destaque?.icones : "",
    },
    secaoRecebaNovidades: {
      imagem: receiveNews?.background_imagem
        ? strapiImg(receiveNews.background_imagem, "", true)
        : "",

      email: receiveNews?.email_de_envio ? receiveNews.email_de_envio : "",
    },
  };

  return {
    props: {
      appLayout,
      homePage,
    },
    revalidate: 60,
  };
}
